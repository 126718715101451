import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { Link } from "@/helpers/common_helper";
import { pagelink } from "@/config/site";
import { Anchor, Button, UnstyledButton } from '@mantine/core';
import { useRouter } from 'next/router';
export default function Index() {
  const { t } = useTranslation('common');
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Page Not Found | {t('Site_name')}</title>
      </Head>

      <style dangerouslySetInnerHTML={{__html: `
        footer {
          position: fixed;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1030;
        }
      `}}></style>

      <section className="login__form">
        <div className="login__form_layout">
          {/* <div className="title">
            <h1>404</h1>
          </div> */}
          <div className='page-content pt-5' style={{ color: '#102945' }}>
            <h1 className='text-center fw-bold fof-text' style={{ fontSize: 150 }}>404</h1>
            <h2 className='text-center py-2'>Opps, This Page Not Found!</h2>
            <h6 className='text-center py-1 text-black-50'>The link might be corrupted.</h6>
            <h6 className='text-center py-1'>or the page may have been removed.</h6>
            <div className='text-center'>
              <button
                  onClick={() => { router.push('/') }}
                  className='btn shadow-none login-btn-pill-bg btn-filled gradient-hover-effect border-0 text-decoration-none d-flex justify-content-center align-content-center align-items-center align-self-center mx-auto'
                  style={{ fontSize: '1.5rem', height: 75, borderRadius: 35 }}
              >Back To Home</button>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center flex-column align-items-center">
            <Link href={pagelink.home.homepage}>
              <button className="btn shadow-none login__btn_pill_bg mt-0">
                {t('Back_to_home')}
              </button>
            </Link>
          </div> */}
        </div>
      </section>
    </>
  )
}

export async function getStaticProps({ locale, params }) {
  return {
    props: {
      ...params,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
